<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }}
      </h1>
      <div class="panel" style="padding: 1em 0">
        <div class="columns" style="margin: 1.2em;">
          <div class="column is-2">
            <b-datepicker
                v-model="fromdate"
                placeholder="ຈາກວັນທີ"
                :locale="this.getDateLocale"
                icon="calendar-today"
            >
            </b-datepicker>
          </div>
          <div class="column is-2">
            <b-datepicker
                v-model="todate"
                placeholder="ຫາວັນທີ"
                :locale="this.getDateLocale"
                icon="calendar-today"
            >
            </b-datepicker>
          </div>
          <div class="column is-3"  v-if="user.role==='ADMIN'">
          <b-select placeholder="ສາຂາ" expanded v-model="branchId">
            <option :value="0"  :selected="branchId==0">ທັງໝົດ</option>
            <option v-for="option in branchs"
                    :selected="branchId==option.id"
                    :value="option.id"
                    :key="option.id">
              {{ option.name }}
            </option>
          </b-select>
        </div>
          <div class="column is-3">
            <b-field horizontal>
              <b-input
                  v-model="keyword"
                  placeholder="ຊອກຫາ ດ້ວຍ ລະຫັດ, ຊື່ ຫຼື ເບີໂທ"
                  @keyup.native.enter="search()"
              />
              <p class="control" v-if="isAndroid">
                <b-button
                    icon-left="barcode"
                    class="button is-primary"
                    @click="scanBarcode"
                ></b-button>
              </p>
            </b-field>
          </div>
          <div class="column is-2">
            <button class="button" @click="search()">
              <b-icon icon="magnify" />
            </button>
             <b-button @click="exportExcel" v-if="models.length>0">
            <b-icon
                icon="file-excel-outline"
                style="margin-right: 0.5em"></b-icon>Excel
          </b-button>
          </div> 
        </div>

      </div>
      <card-component class="has-table has-mobile-sort-spaced">
        <b-table
            :loading="isLoading"
            :paginated="perPage < models.length"
            :per-page="perPage"
            :striped="true"
            :hoverable="true"
            default-sort="name"
            :data="models"
            :current-page.sync="currentPage"
            :pagination-rounded="true"
        >
          <b-table-column label="#" v-slot="props">
            {{ props.index+(currentPage>1?(perPage*currentPage-perPage):0)+1 }}
          </b-table-column>
          <b-table-column
              label="ວັນທີ່ສົ່ງ"
              field="code"
              sortable
              v-slot="props"
          >
            {{ props.row.created_date | datetime }}
          </b-table-column>
          <b-table-column label="ລະຫັດ" field="code" sortable v-slot="props">
            {{ props.row.code }}
          </b-table-column>
          <b-table-column
              label="ສາຂາຕົ້ນທາງ"
              field="receivername"
              sortable
              v-slot="props"
          >
            {{ props.row.branch_branchToshipment_from_branch_id.name }}
          </b-table-column>
          <b-table-column
              label="ຜູ້ສົ່ງ"
              field="sendername"
              sortable
              v-slot="props"
          >
            {{ props.row.sendername }}
          </b-table-column>
          <b-table-column
              label="ຜູ້ຮັບ"
              field="receivername"
              sortable
              v-slot="props"
          >
            {{ props.row.receivername }}
          </b-table-column>
          <b-table-column
              label="ຄ່າຝາກ"
              field="amount"
              sortable
              v-slot="props"
              cell-class="has-text-right"
          >
            {{ props.row.amount | formatnumber }}
          </b-table-column>
          <b-table-column
              label="ການຊໍາລະຄ່າຝາກ"
              field="is_receiver_pay"
              sortable
              v-slot="props"
              cell-class="has-text-centered"
          >
            <span v-if="!props.row.is_receiver_pay" class="tag is-success">
              ຈ່າຍແລ້ວ
            </span>
            <span
                v-if="props.row.is_receiver_pay && !props.row.is_fee_in_cod"
                class="tag is-warning"
            >
              ເກັບຄ່າສົ່ງປາຍທາງ
            </span>
            <span
                v-if="props.row.is_receiver_pay && props.row.is_fee_in_cod"
                class="tag is-danger"
            >
              ເກັບຄ່າສົ່ງປາຍທາງ (ຫັກຈາກ COD)
            </span>
          </b-table-column>
          <b-table-column
              custom-key="actions"
              cell-class="is-actions-cell"
              v-slot="props"
          >
            <div class="buttons is-right">
              <b-tooltip label="ເບີ່ງລາຍລະອຽດ" type="is-info"
                         position="is-left">
              <b-button
                  class="is-small is-info"
                  @click="view(props.row)"
                  icon-left="eye"
              >
              </b-button> </b-tooltip>
            </div>
          </b-table-column>
          <empty-table v-if="models.length == 0" :isLoading="isLoading" />
        </b-table>
      </card-component>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import CardComponent from "@/components/CardComponent";
import EmptyTable from "../components/EmptyTable.vue";
//import Swal from "sweetalert2";
import {get} from "vuex-pathify";
import moment from "moment";
import store from "@/store";
import XLSX from "xlsx";


export default {
  name: "CanceledShipment",
  components: { EmptyTable, CardComponent },
  computed: {
    user: get("user"),
    getDateLocale() {
      return "en-GB";
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      title: "ເຄື່ອງຖືກຍົກເລີກການສົ່ງ",
      model: null,
      models: [],
      isShowModal: false,
      isLoading: false,
      keyword: null,
      fromdate: new Date(),
      todate: new Date(),
      branchId: 0,
      branchs: [],
    };
  },
  mounted() {
    this.branchId=0;
    this.getBranch();
    this.getUserBranchId();
    this.search();
    
  },
  watch: {
    models: function () {
      this.isLoading = false;
    },
  },
  methods: {
     getUserBranchId(){
      this.branchId=store.getters.getUserBranchId;
    },
    getUserBranchInfo() {
      return store.getters.getUserIdBranchInfo;
    },
    getBranch(){
      this.branchs=store.getters.getBranch;
    },
    view(item) {
      this.$router.push({ path: "/shipment/" + item.code });
    },
    search() {
      //console.log(this.branchId);
      this.isLoading = true;
      const fdate = moment(this.fromdate).format("YYYY-MM-DD");
      const tdate = moment(this.todate).format("YYYY-MM-DD");
      if(this.keyword==='') this.keyword=null;
      axios.get(`shipments/canceled/${fdate}/${tdate}/${this.branchId}/${this.keyword}`).then((r) => {
        this.models = r;
      });
    },

    find(barcode) {
      console.log("finding barcode: ", barcode);
      axios
          .get(`shipment/findholding/${barcode}`)
          .then((parcel) => {
            this.$router.push({
              path: "/shipment/" + parcel.code,
              params: { propShipment: this.shipment },
            });
          })
          .catch((e) => {
            console.log(e);
            this.$buefy.snackbar.open({
              message: "Barcode not found",
              queue: false,
            });
          });
    },

    scanBarcode() {
      this.$buefy.snackbar.open({
        message: "Scan barcode",
        queue: false,
      });

      try {
        return window.AndroidApp.scanBarcode("dlBarcodeScanned");
      } catch (e) {
        console.log(e);
      }
    },

    toDeliverParcelsBarcodeScanned(barcode) {
      this.$buefy.snackbar.open({
        message: `Scan barcode : ${barcode}`,
        queue: false,
      });
      if (barcode !== undefined && barcode.length > 0) {
        this.find(barcode);
      }
    },
        exportExcel() {
      if (this.models.length<1 ) return;
      //Create workBook
      const workBook = XLSX.utils.book_new();
      /* worksheet  data */
      const header =[
"ວັນທີ່ສົ່ງ"
,"ລະຫັດ"
,"ສາຂາຕົ້ນທາງ"
,"ຜູ້ສົ່ງ"
,"ຜູ້ຮັບ"
,"ຄ່າຝາກ"
,"ການຊໍາລະຄ່າຝາກ"
      ];

      const ws_data_sheet = [];
      ws_data_sheet.push(header);
      for (const item of this.models) {
        ws_data_sheet.push([
          moment(item.created_date).format("YYYY-MM-DD HH:mm:ss")
          ,item.code
          ,item.branch_branchToshipment_from_branch_id.name
          ,item.sendername 
          ,item.receivername 
          ,item.amount
          ,item.is_receiver_pay?"ຄ່າຝາກເກັບປາຍທາງ":""
        ]);
      }
      /* create worksheet */
      const ws = XLSX.utils.aoa_to_sheet(ws_data_sheet);
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(workBook, ws, "ຄ່າສົ່ງເຄື່ງ");

      /* Write workbook to file */
      const _date = moment(this.selectedDate).format("YYYY_MM_DD_HHmmss");
      const _ub = this.getUserBranchInfo();
      XLSX.writeFile(workBook, `Canceled_shipment_ON_${_ub}_FILE_${_date}.xlsx`);
    },

  },
  beforeMount() {
    //Create interface between Webview and Native application
    window["MyWebView"] = {
      components: this,
      dlBarcodeScanned: (barcode) =>
          this.toDeliverParcelsBarcodeScanned(barcode),
    };
  },
};
</script>